/*libraries*/
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import { useDispatch, useSelector } from 'react-redux';
import ErrorPage from '../components/exceptions/ErrorPage';
import jwt_decode from 'jwt-decode'

import routesData from "./routes"
import { useState,useEffect, useMemo } from 'react';
import { useAuthProvider, useBackendTokenCheckExpirationTime } from '../config/authProvider';
import moment from "moment-timezone";
import { LOCAL_TIMEZONE } from '../store/constant';

const isTokenExpired = (token) => {
  const decoded = jwt_decode(token);
  const now = Math.floor(Date.now()/1000);
  return decoded.exp < now;
};

export function ProtectedRoute({ children }) {
  const token = useSelector((state) => state.token);
  const isLoggedIn = useSelector((state) => state.isLoggedIn);
  return isLoggedIn && !isTokenExpired(token) ? children : <Navigate to="/" />;
};

export function PublicRoute ({ children }) {
  const isLoggedIn = useSelector((state) => state.isLoggedIn);
  const isAdmin = useSelector((state) => state.isAdmin);
  const token = useSelector((state) => state.token);

  let currenthost = `${window.location.pathname}`
  if(!isAdmin && isLoggedIn && !currenthost.includes("request_access")){
    return <Navigate to="/request_access"/>
  }
  return isLoggedIn && !isTokenExpired(token) ? <Navigate to='/dashboard' /> : children;
};

const Myroutes = () => {
  const history = createBrowserHistory();
  const dispatch = useDispatch();
  const user = useSelector(state=>state.user)
  const isAdmin = useSelector(state=>state.isAdmin)
  const isSuperAdmin = useSelector(state=>state.isSuperAdmin)
  const isLoggedIn = useSelector(state=>state.isLoggedIn)

  const {handleDispatch} = useAuthProvider()
  const checkToken = useBackendTokenCheckExpirationTime()
  const { instance, accounts, inProgress } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const [userRole,setUserRole] = useState({
    isAdmin:false,
    isSuperAdmin:false,
  })

  const localTimeZone = useMemo(() => {
    const timeZone = moment.tz(moment.tz.guess()).format("z");
    console.log(timeZone);
    return isNaN(Number(timeZone)) ? timeZone : "local";
  },[]);

  useEffect(() => {
    const account = instance.getActiveAccount();
    
    
    const groups = accounts[0]?.idTokenClaims?.groups || []
    const isAdmin = groups.filter(val=>val.includes("RSMAdmin")).length>0?true:false;
    const isSuperAdmin = groups.filter((val)=> val.includes("RTPaaS-RSM-AMS-SCCM Admins")).length>0?true:false
    setUserRole((prev)=>({...prev,isAdmin,isSuperAdmin}))
    dispatch({type: LOCAL_TIMEZONE,payload:localTimeZone});
    if(!isLoggedIn){
      handleDispatch();
    }
  }, [isAuthenticated, accounts,isLoggedIn, dispatch, instance,handleDispatch])

  useEffect(()=>{
    if(isLoggedIn){
      setUserRole((prev)=>({...prev,isAdmin:isAdmin,isSuperAdmin:isSuperAdmin}))
    }
  },[user,isAdmin,isSuperAdmin,isLoggedIn])

  return (
    <Router history={history}>
      <Routes>
          {
            routesData.map(({path, component,outlet,role}, index)=> 
              
              {if(((userRole.isAdmin && role==="admin") || (userRole.isSuperAdmin && role==="superadmin") || (role === "all"))){
                return <Route key={path} path={path} element={component}>{outlet && <Route index element={outlet}/>}</Route>
              }else{
                return null
              }
            }
            )
          } 
      </Routes>
    </Router>
  )
}

export default Myroutes;