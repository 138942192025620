import {  useState } from 'react';
import { useSelector } from 'react-redux';
import {Link} from 'react-router-dom';
// import {routing} from "./routing"
import clsx from "clsx"
import Img from "../assets/img/profile.jpg";
// import $ from 'jquery';
import { useLocation } from 'react-router-dom';

const Sidebar = () =>{
    const sidebarActive = useSelector(state => state.sidebarActive)
    const isAdmin = useSelector(state => state.isAdmin)
    const isSuperAdmin = useSelector(state => state.isSuperAdmin)
    const user = useSelector(state => state.user)

    const location = useLocation();
    // let url_array = window.location.href.split('/');

    // let url_val = url_array[url_array.length - 1];

    // const[url] = useState(url_val
  // const isSidebarShrink=!sidebarActive && showsubmenu 
    const constants = {
        dashboard: "dashboard",
        packages:"packages",
        applications:"applications",
        admin: "admin",
        access: "access",
        marketcalendar:"marketcalendar"
    }
    const [showDrop, setShowDrop] = useState({
        dashboard:false,
        packages:false,
        applications:false,
        admin: false,
        access: false,
    })

    const toggleDropdown = (val) => {
        const newShowDrop = {...showDrop, [val]: !showDrop[val]};
        Object.keys(showDrop).forEach(key => {
            if (key !== val) {
                newShowDrop[key] = false;
            }
        });
        setShowDrop(newShowDrop);
    }

	return (
		<>	
	        <div className={
                clsx([
                    "col-sm-2",
                    "col-xs-6",
                    "sidebar",
                    "pl-0",
                    {"shrink-sidebar": !sidebarActive}
                ])
            }>
                <div className="inner-sidebar mr-3">
                    <div className="sidebar-menu-container">
                        <ul className={`sidebar-menu mb-4 ${sidebarActive ? "ml-2 mr-2" : ""} `}>

                            <li className="parent ml-3 mb-4">
                                <div className="form-inline my-2 my-lg-0">
                                    <div className="mr-2 mr-xs-1 d-flex align-items-center">
                                        <img
                                        src={Img}
                                        alt="Adam"
                                        className="rounded-circle h-100 w-100"
                                        style={{ maxHeight: "42px", maxWidth: "42px", border:"2px solid #DA291C" }}
                                        width="42px"
                                        height="42px"
                                        />
                                    </div>
                                    { sidebarActive && <div className='d-flex flex-column ml-1' id='user-details-sidebar'>
                                        <span>{user.idTokenClaims.given_name + " " + user.idTokenClaims.family_name}</span>
                                        <p className='text-muted' style={{fontSize:"12px"}}>{isSuperAdmin? "SuperAdmin":isAdmin?"Admin":"User"}</p>
                                    </div>}
                                </div>
                            </li>
                            <li className="parent" onClick={()=>{toggleDropdown(constants.dashboard)}}>
                                <Link to={'/dashboard'}  className={(window.location.pathname === "/dashboard")?'active':''}>
                                    <i className="fa fa-dashboard mr-2"></i>
                                    <span className="none">Home</span>
                                </Link>
                            </li>
                            {isAdmin &&
                            <>
                            <li className="parent" >
                                    <div onClick={()=>{toggleDropdown(constants.packages)}}>
                                        <Link to={`${location.pathname}${location.search}${location.hash}`} >
                                                <i className="fa fa-check-square-o mr-2"  ></i>
                                                <span className="none">Package Functions<i className="fa fa-angle-down pull-right align-bottom"></i></span>
                                        </Link>
                                    </div>
                                    {/* <ul  id="submit_request" className={((showsubmenu && !sidebarActive) || showsubmenu)? "subMenu children": "submenu-inactive children" }
                                    > */}
                                    <ul id='submit_request' 
                                        className={clsx("children",
                                            [{"subMenu":showDrop.packages},{"submenu-inactive": !showDrop.packages}]
                                        )}
                                    >
                                         
                                        <>
                                            <li className="child" 
                                            onClick={()=>{
                                                      if(!sidebarActive){
                                                        toggleDropdown(constants.packages)
                                        }
                                    }}
                                    >
                                                <Link to={'/create_package'}  className={(window.location.pathname === "/create_package")?'active':''}>
                                                    {/* <i className="fa fa-angle-right mr-2"></i> */}
                                                    <span>Create New </span>
                                                </Link>
                                            </li>
                                            <li className="child" onClick={()=>{
                                                   if(!sidebarActive){
                                                    toggleDropdown(constants.packages)
                                        }
                                    }} >
                                                <Link to={'/deploy_package'}  className={(window.location.pathname === "/deploy_package")?'active':''}>
                                                    {/* <i className="fa fa-angle-right mr-2"></i> */}
                                                    <span>Deploy New / Existing </span>
                                                </Link>
                                            </li>
                                        <li className="child" onClick={()=>{
                                        if(!sidebarActive){
                                            toggleDropdown(constants.packages)
                                        }
                                    }}>
                                            <Link to={'/list_package'}  className={(window.location.pathname === "/list_package")?'active':''}>
                                                {/* <i className="fa fa-angle-right mr-2"></i> */}
                                                <span>List Prior Requests </span>
                                            </Link>
                                        </li>
                                        </>
                                        
                                    </ul>
                            </li>
                            <li className="parent" >
                                    <div onClick={()=>{toggleDropdown(constants.applications)}}>
                                        <Link to={`${location.pathname}${location.search}${location.hash}`} >
                                                <i className="fa fa-check-circle mr-2"  ></i>
                                                <span className="none">Application Functions <i className="fa fa-angle-down pull-right align-bottom"></i></span>
                                        </Link>
                                    </div>
                                    <ul id='submit_application' 
                                        className={clsx("children",
                                            [{"subMenu":showDrop.applications},{"submenu-inactive": !showDrop.applications}]
                                        )}
                                    >
                                        {isAdmin && 
                                        <>
                                            <li className="child" 
                                            onClick={()=>{
                                                      if(!sidebarActive){
                                                        toggleDropdown(constants.applications)
                                        }
                                    }}
                                    >
                                                <Link to={'/create_application'}  className={(window.location.pathname === "/create_application")?'active':''}>
                                                    {/* <i className="fa fa-angle-right mr-2"></i> */}
                                                    <span>Create New </span>
                                                </Link>
                                            </li>
                                            <li className="child" onClick={()=>{
                                                   if(!sidebarActive){
                                                    toggleDropdown(constants.applications)
                                        }
                                    }} >
                                                <Link to={'/deploy_application'}  className={(window.location.pathname === "/deploy_application")?'active':''}>
                                                    {/* <i className="fa fa-angle-right mr-2"> </i> */}
                                                    <span> Deploy New / Existing </span>
                                                </Link>
                                            </li>
                                        <li className="child" onClick={()=>{
                                        if(!sidebarActive){
                                            toggleDropdown(constants.packages)
                                        }
                                    }}>
                                            <Link to={'/list_application'}  className={(window.location.pathname === "/list_application")?'active':''}>
                                                {/* <i className="fa fa-angle-right mr-2"></i> */}
                                                <span>List Prior Requests </span>
                                            </Link>
                                        </li>
                                        </>
                                        }
                                    </ul>
                            </li>
                            </>
                            }
                            <li className="parent" >
                                    <div onClick={()=>{toggleDropdown(constants.access)}}>
                                        <Link to={`${location.pathname}${location.search}${location.hash}`} >
                                                <i className="fa fa-shield mr-2">&nbsp;</i>
                                                <span className="none">Access Functions<i className="fa fa-angle-down pull-right align-bottom"></i></span>
                                        </Link>
                                    </div>
                                    {/* <ul  id="submit_request" className={((showsubmenu && !sidebarActive) || showsubmenu)? "subMenu children": "submenu-inactive children" }
                                    > */}
                                    <ul id='submit_access' 
                                        className={clsx("children",
                                            [{"subMenu":showDrop.access},{"submenu-inactive": !showDrop.access}]
                                        )}
                                    >
                                        <li className="child" onClick={()=>{
                                            if(!sidebarActive){
                                                toggleDropdown(constants.access)
                                            }
                                        }}>
                                            <Link to={'/request_access'}  className={(window.location.pathname === "/request_access")?'active':''}>
                                                {/* <i className="fa fa-angle-right mr-2"></i> */}
                                                <span>Add Access </span>
                                            </Link>
                                        </li>
                                        {/* <li className="child" onClick={()=>{
                                            if(!sidebarActive){
                                                toggleDropdown(constants.access)
                                            }
                                        }}>
                                            <Link to={'/remove_access'}  className={(window.location.pathname === "/remove_access")?'active':''}>
                                                <span>Remove Access </span>
                                            </Link>
                                        </li> */}
                                        <li className="child" onClick={()=>{
                                            if(!sidebarActive){
                                                toggleDropdown(constants.access)
                                            }
                                        }}>
                                            <Link to={'/list_access'}  className={(window.location.pathname === "/list_access")?'active':''}>
                                                {/* <i className="fa fa-angle-right mr-2"></i> */}
                                                <span>List Prior Requests </span>
                                            </Link>
                                        </li>
                                    </ul>
                            </li>
                            <li className="parent" onClick={()=>{toggleDropdown(constants.dashboard)}}>
                                <Link to={'/client_health'}  className={(window.location.pathname === "/client_health")?'active':''}>
                                    <i className="fa fa-medkit mr-2"></i>
                                    <span className="none">Device Health</span>
                                </Link>
                            </li>
                            {
                                isSuperAdmin && 
                            <li className="parent" onClick={()=>{toggleDropdown(constants.admin)}}>
                                <Link to={{}}>
                                    <i className="fa fa-lock mr-2" >&nbsp;</i>
                                    <span className="none">Admin Functions <i className="fa fa-angle-down pull-right align-bottom"></i></span>
                                </Link>
                                    <ul  id="admin"  className={clsx("children",
                                            [{"subMenu":showDrop.admin},{"submenu-inactive": !showDrop.admin}]
                                        )}>
                                        <li className="child">
                                            <Link to={'/modify_reference_table'}  className={(window.location.pathname === "/modify_reference_table")?'active':''}>
                                                {/* <i className="fa fa-angle-right mr-2"></i> */}
                                                <span onClick={()=>{
                                        // if(!sidebarActive){
                                        if(sidebarActive){
                                            toggleDropdown(constants.admin)
                                        }
                                    }}>Reference Table Support </span>
                                            </Link>
                                        </li>
                                       
                                    </ul>
                            </li>
                            }
                            {/* <li className="parent" onClick={()=>{toggleDropdown(constants.marketcalendar)}}>
                                <Link to={'/market_calendar'}  className={(window.location.pathname === "/market_calendar")?'active':''}>
                                    <i className="fa fa-dashboard mr-2"></i>
                                    <span className="none">Market Calendar</span>
                                </Link>
                            </li> */}
                        </ul>
                        
                    </div>
                </div>
            </div>
		</>
	);
}

export default Sidebar;