const validationNotRequired = ["store_filter","country_filter","create_package_check","create_collection_check","checkbox"];

export const packagename = async (name,code) =>{
    const regexTwo = /^[A-Za-z]{2}\s-/;
    const regexOne = /^[A-Za-z]{2}-/;
    
    if (name.match(regexTwo) || name.match(regexOne)) {
        let subStr = name.slice(0,2);;
        if(subStr.toLowerCase() !== code.toLowerCase()){
            name = code + '-'+ name;
        }
    }else{
        name = code + '-'+ name;
    }
    return name;
}

export const getDate=()=>{
    var date = new Date();
    return (((date.getMonth() > 8) ? (date.getMonth() + 1) : ('0' + (date.getMonth() + 1))) + '/' + ((date.getDate() > 9) ? date.getDate() : ('0' + date.getDate())) + '/' + date.getFullYear());
}

export const addDays = (date = new Date(),numOfDays) => {
    date.setDate(date.getDate() + numOfDays);
    return date;
}

export const filterProgram = async (formValues) =>{
    formValues.filter(function(b){
        delete b.is_new;
        delete b.index;
        return b;
    });
      return formValues;
}

export const showFormErrors = ()=>{
    var elements = "input,select,textarea";
    const inputs = document.querySelectorAll(elements);
    let isFormValid = true;

    inputs.forEach(input => {
        const name = input.name;
        const isCheckbox=(input.type==="checkbox");
        if(!validationNotRequired.includes(name)&& !isCheckbox){
            input.classList.add('active');
            input.closest('.input-group').classList.add('mb-3');

            const isInputValid = showInputError(input);

            if (!isInputValid) {
                isFormValid = false;
                input.closest('.input-group').classList.remove('mb-3');
            }
        }
    });

    return isFormValid;
}

const showInputError = (input)=>{
    const validity = input.validity;
    const label = input.closest('.input-group').previousSibling && input.closest('.input-group').previousSibling.textContent;
    const error = input.closest('.input-group').nextSibling === null?{}:input.closest('.input-group').nextSibling;
    if (!validity.valid) {
        if (validity.valueMissing) {
            error.textContent = `${label} is a required field`; 
        }else if (validity.typeMismatch) {
            error.textContent = `${label} should be a valid email address`; 
        }
        return false;
    }

    error.textContent = '';
    return true;
}

export const convertToUTC = (date)=>{
    return new Date(date).toUTCString();
}

export const globalNew =() =>{
    return {
            CONST_CLIENTID: process.env.REACT_APP_CONST_CLIENTID,
            CONST_AUTHORITY:process.env.REACT_APP_CONST_AUTHORITY,
            CONST_REDIRECTURI:process.env.REACT_APP_CONST_REDIRECTURI,
            API_HOST_LIVE:process.env.REACT_APP_API_HOST_LIVE,
            SCOPES: process.env.REACT_APP_SCOPES.split(',').map(item => item.trim())
      }
}